
    import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
    import { checkPhone, checkSPhone, checkHPhone } from './../../utils/validate'
    import { secretKey } from './../../utils/secretKey'

    @Component({
        name: 'UseName'
    })
    export default class UseName extends Vue {
        @Prop() placeholder!: string
        @Prop() parent!: string
        @Prop() type!: string
        private phone: string = ''
        private phoneType: string = '65'
        private status: number = 0

        private focusEvent(): void {
            this.status = 0
            this.usenameCb(0)
        }
        @Emit()
        private usenameCb(status: number): any {
            return { phone: this.phone, status: status, phoneType: this.phoneType }
        }
        private phoneTypeChange (): void {
            this.usenameCb(1)
        }
        private blurEvent (): void {
            if(this.phone) {
                if(checkSPhone(this.phone) || this.placeholder == '姓名') {
                    this.status = 1
                } else {
                    this.status = 2
                }
            } else {
                this.status = 2
            }
            this.usenameCb(1)
        }
        beforeMount() {
            let _user: any = localStorage.getItem('fangtian_user')
            if(_user && this.parent == '2') {
                this.phone = secretKey(_user, 'phone', 'decryption')
            }
        }
    }
