/*
 * @Author: fremember
 * @Date: 2021-01-08 19:31:25
 * @Description:
 */
/**
 * @description 判断是不是外部链接
 * @param path string
 * @return { boolean }
 */
export function isExternal (path: string): boolean {
    return /^(https?:|mailto:|tel:)/.test(path)
}
/**
 * 判断手机号
 */
export function checkPhone (phone: string): boolean {
    return /^1[3456789]\d{9}$/.test(phone)
}
/**
 * 验证新加坡手机号格式
 * */
export function checkSPhone (phone: string): boolean {
    // return /^((1[3456789]\d{9})|([89]\d{7})|([569])\d{7})$/.test(phone)
    return /^[89]\d{7}$/.test(phone)
}
/**
 * 验证香港手机号格式
 * */
export function checkHPhone (phone: string): boolean {
    return /^[5689]\d{7}$/.test(phone)
}
/**
 * 判断身份证号
 */
export function checkCard (input: string): boolean {
    return /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(input)
}
