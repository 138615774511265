export default {
    'course.t1': 'Chosen conditions',
    'course.t2': 'None',
    'course.t3': 'Clear all',
    'course.t4': 'Year',
    'course.t5': 'Grade',
    'course.t6': 'Subject',
    'course.t7': 'No data available',
    'course.t8': 'Chosen conditions',
    'course.t9': 'Class Type',
    'course.t10': 'Your current test level',
    'course.t11': 'Please select class type',
    'course.t12': 'Full',
    'course.t13': 'Campus',
    'course.t14': 'Please select Campus',
    'course.t15': 'Teacher',
    'course.t16': 'Please select teacher',
    'course.t17': 'Schedule',
    'course.t18': 'Please select a class time',
    'course.t19': 'Date',
    'course.t20': 'Popular',
    'course.t21': 'Tight',
    'course.t22': 'Course Introduction',
    'course.t23': 'Teachers',
    'course.t24': 'Course Features',
    'course.t25': 'Teaching Statement',
    'course.t26': 'Test for admission',
    'course.t27': 'Frequency',
    'course.t28': 'Sessions',
    'course.t29': 'Time',
    'course.t30': 'Classroom',
    'course.t31': 'Register',
    'course.t32': 'Note: Please register for courses according to test levels',
    'course.t33': 'Book a free diagnostic test!',
    'course.t34': 'Popular Courses',
    'course.t35': 'School',
    'course.t36': 'Modify',
    'course.t37': '无',
    'course.t38': '无',
    'course.t39': '无',
    'course.t40': '无',
    'course.t41': '无',
    'course.t42': '无',
    'course.t43': '无',
    'course.t44': '无',
    'course.t45': '无',
    'course.t46': '无',
    'course.t47': '无',
    'course.t48': '无',
    'course.t49': '无',
}
