export default {
    'center.t1': 'Personal Information',
    'center.t2': 'Study',
    'center.t3': 'My Courses',
    'center.t4': 'Do homework',
    'center.t5': 'Assignment Favorites',
    'center.t6': 'Academic Affairs',
    'center.t7': 'Transfer Classes',
    'center.t8': 'Test Score Checking',
    'center.t9': 'Name',
    'center.t10': 'Please enter your name',
    'center.t11': 'Original password',
    'center.t12': 'Please enter the original password',
    'center.t13': 'Gender',
    'center.t14': 'Male',
    'center.t15': 'Female',
    'center.t16': 'Student Number',
    'center.t17': 'New password',
    'center.t18': 'New password (8-12 digits)',
    'center.t19': 'ID card',
    'center.t20': 'Please enter your ID number',
    'center.t21': 'Select file',
    'center.t22': 'Phone Number',
    'center.t23': 'Please enter your phone number',
    'center.t24': 'School',
    'center.t25': 'Please select (fuzzy search supported)',
    'center.t26': 'Grade',
    'center.t27': 'Father\'s Occupation',
    'center.t28': 'Entry of father\'s occupation information',
    'center.t29': 'Mother\'s Occupation',
    'center.t30': 'Enter mother\'s occupation information',
    'center.t31': 'Address',
    'center.t32': 'Subscribe to Daily Practice Incomplete Reminder',
    'center.t33': 'Edit',
    'center.t34': 'Save',
    'center.t35': 'Name cannot be empty',
    'center.t36': 'Wrong password format',
    'center.t37': 'Original password cannot be empty',
    'center.t38': 'Grade cannot be empty',
    'center.t39': 'Teacher',
    'center.t40': 'Class Location',
    'center.t41': 'Class time',
    'center.t42': 'Course Price',
    'center.t43': 'View Class Schedule',
    'center.t44': 'Countdown',
    'center.t45': 'Cancel Order',
    'center.t46': 'Start Lesson',
    'center.t47': 'View courseware',
    'center.t48': 'View Interactive courseware',
    'center.t49': 'After-lesson test',
    'center.t50': 'Please read and sign the FangtianTalning serice agreement',
    'center.t51': 'Lessons',
    'center.t52': 'Class Status',
    'center.t53': 'Type',
    'center.t54': 'Operation',
    'center.t55': 'Lesson ends',
    'center.t56': 'No class',
    'center.t57': 'offline',
    'center.t58': 'Online',
    'center.t59': 'Playback',
    'center.t60': 'No data available',
    'center.t61': 'To Be Paid Orders',
    'center.t62': 'Currently Taking Courses',
    'center.t63': 'Upcoming Courses',
    'center.t64': 'Completed courses',
    'center.t65': 'Closed Courses',
    'center.t66': 'To Be Paid',
    'center.t67': 'Currently',
    'center.t68': 'Upcoming',
    'center.t69': 'Completed',
    'center.t70': 'Closed',
    'center.t71': 'Phone number',
    'center.t72': 'Exam Time',
    'center.t73': 'Subject',
    'center.t74': 'Score',
    'center.t75': 'Admission Ticket',
    'center.t76': 'Grade Rank',
    'center.t77': 'Recommended Class Type',
    'center.t78': 'Subscribe to daily practice incomplete reminders',
    'center.t79': 'Contain Explanation',
    'center.t80': 'Does not contain an explanation',
    'center.t81': 'Submit answer',
    'center.t82': 'Add to print',
    'center.t83': 'Today\'s Daily Practice',
    'center.t84': 'Past Daily Practice',
    'center.t85': 'Notebook',
    'center.t86': 'Wrong Question Book',
    'center.t87': 'Student Information',
    'center.t88': 'Level',
    'center.t89': 'Course Procedure',
    'center.t90': 'Select a course to be transferred temporarily/permanently',
    'center.t91': 'Select Business Type',
    'center.t92': 'Temporary class transfer',
    'center.t93': 'Permanent transfer',
    'center.t94': 'Select target class',
    'center.t95': 'Business Type',
    'center.t96': 'Search',
    'center.t97': 'No Student',
    'center.t98': 'secrecy',
    'center.t99': 'Hint',
    'center.t100': 'The current class is over online',
    'center.t101': 'There are no classes available at this time',
    'center.t102': 'Initial value',
    'center.t103': 'Switch trainee',
    'center.t104': 'Change Order',
    'center.t105': 'Are you sure to drop the class?',
    'center.t106': 'Please contact customer service for refund and cancellation!',
}
