
import { Component, Vue } from 'vue-property-decorator'
import { checkPhone, checkSPhone, checkHPhone } from './../../utils/validate'

import { mapGetters, mapMutations, mapActions } from 'vuex'
import { Caxios } from './../../utils/axios'

import UseName from './../username/index.vue'
import UsePwd from './../password/index.vue'
import SmsCode from './../msgCode/index.vue'


@Component({
  name: 'MineRegister',
  components: { UseName, SmsCode, UsePwd },
  computed: {
    ...mapGetters('common', ['branchschools', 'lang']),
    ...mapGetters('course', ['grade'])
  },
  methods: {
    ...mapMutations('common', ['setRegistrationAgreementFlag', 'setCommonComponentName'])
  }
})
export default class MineRegister extends Vue {
  branchschools!: any
  grade!: any
  setRegistrationAgreementFlag!: any
  setCommonComponentName!: any
  lang!: any
  private usernameMsg: any = ''
  private usernameMsg2: any = ''
  private smscodeMsg: any = ''
  private passwordMsg: any = ''
  private gradeMsg: any = ''
  private locationMsg: any = ''
  private schoolMsg: any = ''

  private loginForm: any = {
    branchSchoolCode: '',
    grade: '',
    name: '',
    password: '',
    phone: '',
    gender: 'female',
    verificationCode: ''
  }
  private province = ''
  private phoneType = ''
  private city = ''
  private curGrade = ''
  private schoolarea = ''
  private schoolFlag = false
  private provinceFlag = false
  private cityFlag = false
  private gradeFlag = false
  private hasSelected = false
  private provinceList: Array<any> = [
    { label: '请选择省', value: '' }
  ]
  private cityList: Array<any> = [
    { city: '请选择市' }
  ]
  private gradeList: Array<any> = [
    { text: this.$t('common.t16') }
  ]
  private schoolList: Array<any> = [
    { text: '请选择分校' }
  ]
  private provinceActiveIndex = -1
  private cityActiveIndex = -1
  private gradeActiveIndex = -1
  private schoolActiveIndex = -1

  private usenameCb (obj: any): void {
    const { phone, status, phoneType } = obj
    console.log('phoneType', phoneType)
    this.loginForm.phone = phone
    let phoneSuccess = false
    if (phoneType === '65') {
      phoneSuccess = checkSPhone(phone)
    } else if (phoneType === '852') {
      phoneSuccess = checkHPhone(phone)
    } else if (phoneType === '86') {
      phoneSuccess = checkPhone(phone)
    }
    this.phoneType = phoneType
    this.loginForm.countryCode = phoneType
    if(phoneSuccess) {
      this.usernameMsg = ''
      this.loginForm.phone = phone
    } else {
      this.usernameMsg = this.$t('common.t6')
      this.loginForm.phone = ''
    }
    if(status == 0) {
      this.usernameMsg = ''
    }
  }
  private usenameCb2 (obj: any): void {
    const { phone, status } = obj
    this.loginForm.name = phone
    if(phone) {
      this.usernameMsg2 = ''
    } else {
      this.usernameMsg2 = this.$t('common.t19')
    }
    if(status == 0) {
      this.usernameMsg2 = ''
    }
  }
  private usepwdCb (obj: any): void {
    const { password, status } = obj
    this.loginForm.password = password
    if(password) {
      if(password.length < 8 || password.length > 12) {
        this.passwordMsg = this.$t('common.t20')
      } else {
        this.passwordMsg = ''
      }
    } else {
      this.passwordMsg = this.$t('common.t21')
    }

    if(status == 0) {
      this.passwordMsg = ''
    }
  }
  private msgcodeCb (obj: any): void {
    const { smscode, status } = obj
    this.loginForm.verificationCode = smscode
    if(smscode) {
      this.smscodeMsg = ''
    } else {
      this.smscodeMsg = this.$t('common.t22')
    }
    if(status == 0) {
      this.smscodeMsg = ''
    }
  }
  private provinceEvent (): void {
    this.provinceFlag = !this.provinceFlag
  }
  private cityEvent (): void {
    this.cityFlag = !this.cityFlag
  }
  private gradeEvent (): void {
    this.gradeFlag = !this.gradeFlag
  }
  private schoolEvent (): void {
    this.schoolFlag = !this.schoolFlag
  }
  private provinceItemEvent (item: any, index: number): void {
    this.province = item.label
    this.provinceActiveIndex = index
    this.cityList = [ { city: '请选择市' } ]
    if(index > 0) {
      this.branchschools[index - 1].list.forEach((v: any): void => {
        this.cityList.push(v)
      })
    }
    this.provinceFlag = false
    this.locationMsg = index == 0 ? '省不能为空' : ''
  }
  private cityItemEvent (item: any, index: number): void {
    this.city = item.city
    this.cityActiveIndex = index
    this.loginForm.branch_school_id = item.id
    this.cityFlag = false
    this.locationMsg = index == 0 ? '市不能为空' : ''
  }

  private gradeItemEvent (item: any, index: number): void {
    this.curGrade = item.label
    this.gradeActiveIndex = index
    this.loginForm.grade = item.value
    this.gradeMsg = index == 0 ? this.$t('common.t23') : ''
    this.gradeFlag = false
  }
  private schoolItemEvent (item: any, index: number): void {
    this.schoolarea = item.name
    this.schoolActiveIndex = index
    this.loginForm.branchSchoolCode = item.code
    this.schoolMsg = index == 0 ? '分校不能为空' : ''
    this.schoolFlag = false
    console.log('点击了1111')
  }
  private curRegisterEvent (): any {
    const _this: any = this
    if(!this.loginForm.phone) {
      this.usernameMsg = this.$t('common.t24')
      _this.$message.warning(this.$t('common.t24'))
      return
    }
    if(!this.loginForm.verificationCode) {
      this.smscodeMsg = this.$t('common.t22')
      _this.$message.warning(this.$t('common.t22'))
      return
    }
    if(!this.loginForm.password) {
      this.passwordMsg = this.$t('common.t21')
      _this.$message.warning(this.$t('common.t21'))
      return
    }
    // if(!this.province) {
    //     this.locationMsg = '省不能为空'
    //     _this.$message.warning('省不能为空')
    //     return
    // }
    // if(!this.city) {
    //     this.locationMsg = '市不能为空'
    //     _this.$message.warning('市不能为空')
    //     return
    // }
    if(!this.loginForm.name) {
      this.usernameMsg2 = this.$t('common.t19')
      _this.$message.warning(this.$t('common.t19'))
      return
    }
    if(!this.loginForm.grade) {
      this.gradeMsg = this.$t('common.t23')
      _this.$message.warning(this.$t('common.t23'))
      return
    }
    if(!this.hasSelected) {
      _this.$message.warning(this.$t('common.t25'))
      return
    }
    // this.loginForm.branch_school_id = '2'
    // this.loginForm.registChannel = 'wy'
    this.registerEvent()
  }
  private async registerEvent () {
    const _result: any = await Caxios.post({ url: '/api/official/user/register', data: this.loginForm }),
        _this: any = this;
    if(_result.code === '10000') {
      _this.$message.success(this.$t('common.t26'))
      this.setCommonComponentName('')
    } else {
      _this.$message.warning(_result.msg)
    }
  }
  private signupEvent (): void {
    this.setRegistrationAgreementFlag(true)
  }
  private async getSchools () {
    const data: any = await Caxios.post({ url: '/api/official/user/branchSchoolList' })
    if (data.code === '10000') {
      this.schoolList = data.data
      this.schoolList.unshift({ text: '请选择分校' })
    }
  }
  beforeMount() {
    console.log('this.grade',this.grade)
    this.gradeList = this.grade
    /*this.branchschools.forEach((item: any, index: number): void => {
      const _tempP: any = {}
      _tempP.label = item.province
      _tempP.value = ''
      this.provinceList.push(_tempP)
    })*/
  }
  mounted () {
    this.getSchools()
  }
}
