import vue from 'vue'
import Confirm from './index.vue'

let ConFirmClass: any = vue.extend(Confirm),
    ConfirmMain = (obj: any) => {
        return new Promise((res: any, rej: any): void => {
            let confirmDom: any = new ConFirmClass()
            confirmDom.options = obj
            confirmDom.$mount(document.createElement('div'))
            document.body.appendChild(confirmDom.$el)

            confirmDom.title = obj.title
            confirmDom.subTitle = obj.subTitle
            confirmDom.isShow = true

            console.log(confirmDom)

            confirmDom.sure = (): void => {

                console.log(123)
                confirmDom.isShow = false
                res()
            }
            confirmDom.cancel = (): void => {

                console.log(456)
                confirmDom.isShow = false
                rej()
            }
        })
    };

function confirm (): void {
    vue.prototype.$confirm = ConfirmMain
}
export default ConfirmMain
