export default {
    'home.c1.t1': '教学好',
    'home.c1.t2': '直击痛点，成就孩子的学霸之旅',
    'home.c1.t3': '教',
    'home.c1.t4': '互动课件',
    'home.c1.t5': '小学低年级采用交互式课件，样式丰富多彩，让孩子爱上学习',
    'home.c1.t6': '小班授课',
    'home.c1.t7': '每个班级人数在20人左右，确保每个孩 子都有良好的学习环境。',
    'home.c1.t8': '学',
    'home.c1.t9': '家长旁听',
    'home.c1.t10': '在符合要求的情况下允许家长进入教室旁听，与孩子一同成长',
    'home.c1.t11': '线下辅导',
    'home.c1.t12': '线下辅导，与老师面对面交流，拉近与 老师的距离。',
    'home.c1.t13': '练',
    'home.c1.t14': '错题本',
    'home.c1.t15': '记录孩子错题，随时查阅，巩固知识点记录自己的题目',
    'home.c1.t16': '天天练',
    'home.c1.t17': '每天一题，巩固上课所学知识点，帮助 学生复习自己上课内容。',
    'home.c2.t1': '陪伴式教学辅导，7步保证学习效果',
    'home.c2.t2': '7步',
    'home.c2.t3': '教学法',
    'home.c2.t4': '课前小测',
    'home.c2.t5': '利用课前20分钟对上节课知识要点进行复习',
    'home.c2.t6': '知识讲解',
    'home.c2.t7': '以例题讲解试做的方式教授本节课的知识点',
    'home.c2.t8': '错题订正',
    'home.c2.t9': '利用课间对课前测的错题进行讲解和订正',
    'home.c2.t10': '课堂直播',
    'home.c2.t11': '由助教老师在班级群图文直播上课内容',
    'home.c2.t12': '课后小测',
    'home.c2.t13': '利用课后20分钟对课上知识要点进行练习',
    'home.c2.t14': '作业巩固',
    'home.c2.t15': '课后对本节课的知识点进行强化和练习',
    'home.c2.t16': '课程总结',
    'home.c2.t17': '通过大数据为每一位学生生成专属成绩报告',
    'home.c3.t1': '分层教学，老师精准授课',
    'home.c3.t2': '培优',
    'home.c3.t3': '注重夯实基础，确保简单题不丢分',
    'home.c3.t4': '提高',
    'home.c3.t5': '注重在基础知识上的再提升',
    'home.c3.t6': '创新',
    'home.c3.t7': '注重对数学难题的学习探索',
    'home.c3.t8': '探索',
    'home.c3.t9': '注重对全国决赛难度题目的学习',
    'home.c3.t10': '方田',
    'home.c3.t11': '全国高中数学联赛、CMO、IMO难度题型的学习和掌握',
    'home.c4.t1': '教研好',
    'home.c4.t2': '自主研发讲义，多次押中中考考题',
    'home.c4.t3': '押题准',
    'home.c4.t4': '多次押中中考考题',
    'home.c4.t5': '方田的讲义已经多次压中中考考题，名校小升初考试，以及四十五中，五十中，四十二中，寿春中学，一中、六中，八中，168中学等初高中学校期中期末考试题。',
    'home.c4.t6': '每次都完美错过做过的题，考试的题型很少见',
    'home.c4.t7': '选题精',
    'home.c4.t8': '自主研发讲义',
    'home.c4.t9': '方田使用的教材是自主研发的讲义，老师经过层层审核，制定出来的教研体系符合学生的认知规律及考试命题规律，同时实行分层教学，不同班型使用不同层级的讲义。',
    'home.c4.t10': '做的题很多，但是提分效果差，题海战术的效果越来越差',
    'home.c4.t11': '模块化',
    'home.c4.t12': '解决一类题型',
    'home.c4.t13': '方田每节课所选用的例题都是围绕知识点展开的，难度层层递进，不仅让学生理解知识点更保证学生会解每一道题，每一类题。再通过课前测课后测不断巩固知识点。',
    'home.c4.t14': '一道题换一种说法就不会做，反反复复总是丢分',
    'home.c5.t1': '学霸归来，2020方田学子谁与争锋',
    'home.c5.t2': '2020方田单科高分榜',
    'home.c5.t3': '数学',
    'home.c5.t4': '单科满分153人，占比12.07%',
    'home.c5.t5': '满分150；',
    'home.c5.t6': '145-149分，达222人；',
    'home.c5.t7': '140-144分，达到156人；',
    'home.c5.t8': '单科成绩140分以上的学员，占比41.88%；',
    'home.c5.t9': '物理',
    'home.c5.t10': '单科满分57人，占比7.79%',
    'home.c5.t11': '满分70分；',
    'home.c5.t12': '物理65-69分，达到268人；',
    'home.c5.t13': '物理60-64分，达到95人；',
    'home.c5.t14': '单科成绩60分以上的学员，占比57.38%；',
    'home.c5.t15': '化学',
    'home.c5.t16': '单科满分153人，占12.07%',
    'home.c6.t1': '学霸归来，2020方田学子谁与争锋',
    'home.c7.t1': '让更优秀的人进入教育行业',
    'home.c7.t2': '投简历',
    'home.c7.t3': '80%的筛选率',
    'home.c7.t4': '从重点大学选优等生',
    'home.c7.t5': '初试',
    'home.c7.t6': '60%的通过率',
    'home.c7.t7': '从优等生中选合适的人',
    'home.c7.t8': '复试',
    'home.c7.t9': '30%的通过率',
    'home.c7.t10': '能力与品质两手抓',
    'home.c7.t11': '培训（7天）',
    'home.c7.t12': '15%的通过率',
    'home.c7.t13': '爱与责任是方田不变的初心',
    'home.c7.t14': '实习（3个月）',
    'home.c7.t15': '5%的通过率',
    'home.c7.t16': '确保每个老师没有短板',
    'home.c7.t17': '最终考核',
    'home.c7.t18': '2.47%的通过率',
    'home.c7.t19': '真正做到优中选优',
    'home.c7.t20': '走向讲台',
    'home.c7.t21': '每位老师都值得信赖',
    'home.c8.t1': '每一位走向讲台的方田老师，都足够出色',
    'home.c8.t2': '备课',
    'home.c8.t3': '每周进行备考，确保老师上课进度统一，上课内容一致，上课质量得到保证。',
    'home.c8.t4': '串题',
    'home.c8.t5': '组长带领大家进行串题，统一讲课思路，保证每个学生都能接受到相同的知识点和解题思路。',
    'home.c8.t6': '赛课',
    'home.c8.t7': '不定期举行赛课，即使走上讲台也要时时检测老师水平，督促老师成长。',
    'home.c8.t8': '考核',
    'home.c8.t9': '开课前统一考核，检测老师的上课功底和备课水平，只有真正优秀的人才能走上讲台。',
    'home.c9.t1': '关于方田',
    'home.c9.t2': '让每个学生都能享受优质的教育',
    'home.c9.t3': '自2014年7月成立以来，方田教育立志用科技改变学习，致力于为6-18岁的中小学生提供高品质的线下及在线课外辅导。截至2018年暑假，方田共培训学员超过40000人次，成为安徽地区中小学生和家长信赖和认可的理科培优教育机构。',
    'home.c9.t4': '为实现对每个学生的精准教学，方田自成立之日起就不断通过技术创新帮助孩子提高学习效率。此外，方田还实行线下小班教学和在线辅导的教育服务体系，并通过“教学七步法”和随堂助教模式，把教学重心从“老师讲得好”转变为“学生学得会”，让每个学生都能得到因材施教的专业辅导。',
    'home.c10.t1': '常见问题',
    'home.c10.t2': 'Q:什么时候可以报名？怎么报名？',
    'home.c10.t3': '测试结束之后就可以凭测试成绩通过手机微信小程序自助报名缴费。',
    'home.c10.t4': 'Q:方田开设了哪些科目？是否会开设其他科目？',
    'home.c10.t5': '方田目前只开设了理科以及语文、英语科目，方田做事的原则是专注，对于开设新的科目是非常慎重的，为了保证良好的教学质量和教学服务，一定是准备充足了才开设新的学科。',
    'home.c10.t6': 'Q:报名之后还能不能升班?',
    'home.c10.t7': '可以，方田的分层教学，是为了将学习层次比较接近的孩子放在同一个班级，这样一方面有利于老师上课时对进度的控制，更重要的是让孩子在合适的班级学习，既能够跟随上课的进度。',
    'home.c10.t8': 'Q:家长可以旁听吗？',
    'home.c10.t9': '可以，家长在遵守上课规则的前提下可以跟学生一起在课堂上进行学习。',
    'home.c10.t10': 'Q:报名后不满意怎么办？',
    'home.c10.t11': '方田支持自由调课转班，家长可以自行在小程序或官网上进行调课转班，转到自己心仪的班级。也可以到前台，让前台帮忙操作。',
    'home.c10.t12': 'Q:报名之后能退费吗?',
    'home.c10.t13': '每学期课程剩余课次不少于三次，均可随时退费，不退单次课。',
}
