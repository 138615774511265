/*
 * @Author: fremember
 * @Date: 2021-01-14 09:29:24
 * @Description:
 */
import { GetterTree } from 'vuex'
import { ActionContext } from './../interface'
import { Caxios } from './../../utils/axios'
import Vue from "@/main";
export interface State {
        personalData: any;
        areaSchoolList: Array<any>;
        allFlag: number;
        attrEmptyFlag: number;
        updateFlag: string;
        hasWorkFlag: boolean;
    }

let state: State = {
        personalData: {},
        areaSchoolList: [],
        allFlag: -1,
        attrEmptyFlag: -1,
        updateFlag: '',
        hasWorkFlag: false
    },
    getters: GetterTree<State, any> = {
        personalData: (state: State) => state.personalData,
        areaSchoolList: (state: State) => state.areaSchoolList,
        allFlag: (state: State) => state.allFlag,
        attrEmptyFlag: (state: State) => state.attrEmptyFlag,
        updateFlag: (state: State) => state.updateFlag,
        hasWorkFlag: (state: State) => state.hasWorkFlag
    },
    mutations = {
        setPersonalData (state: State, personalData: any): void {
            state.personalData = personalData
        },
        setAreaSchoolList (state: State, areaSchoolList: Array<any>): void {
            state.areaSchoolList = areaSchoolList
        },
        setAllFlag (state: State): void {
            state.allFlag = Math.random()
        },
        setAttrEmptyFlag (state: State): void {
            state.attrEmptyFlag = Math.random()
        },
        setUpdateFlag (state: State, updateFlag: string): void {
            state.updateFlag = updateFlag
        },
        setHasWorkFlag(state: State, hasWorkFlag: boolean): void {
            state.hasWorkFlag = hasWorkFlag
        },
    },
    actions = {
        setPersonalData(context: ActionContext, personalData: any): void {
            let { commit } = context
            commit('setPersonalData', personalData)
        },
        async areaSchool (context: ActionContext, schoolId: string) {
            let { commit } = context,
                result: any = await Caxios.post({ url: `/api/official/user/branchSchoolList` });
            commit('setAreaSchoolList', result.data)
        },
        async getPersonData(context: ActionContext, id: string) {
            let { commit } = context,
                result: any = await Caxios.get({ url: `/api/official/student/${id}` });
            if(result.id) {
                commit('setPersonalData', result)
                localStorage.setItem('singapore_personal_Data', JSON.stringify(result))
            }
        },
        async editbase(context: ActionContext, editForm: any) {
            editForm.gender =  editForm.sex
            editForm.school =  editForm.schoolName
            let { commit, dispatch } = context,
                result: any = await Caxios.post({ url: '/api/official/student/update', data: editForm });
            localStorage.setItem('singapore_personal_Data', JSON.stringify(editForm))
            Caxios.post({ url: '/api/official/student/get/students'}).then((su: any) => {
                if (su.code === '10000') {
                    // 通知全局刷新
                    if (su.data) {
                        localStorage.setItem('singapore_branchSchoolId', String(su.data.branchSchoolCode))
                        localStorage.setItem('singapore_personal_Data', JSON.stringify(su.data));
                    } else {
                        localStorage.removeItem('singapore_personal_Data');
                    }

                }
                commit('setPersonalData', JSON.stringify(su.data))
                commit('personalData', JSON.stringify(su.data))
            })
            commit('setUpdateFlag', result.msg ? result.msg : '信息修改成功')
        },
        async getDailyWorksCount (context: ActionContext, id: string) {
            let { commit, dispatch } = context,
                result: any = await Caxios.get({ url: `/api/dailywork/getDailyWorksCount?stdid=${id}` });
            if(result && result > 0) {
                commit('setHasWorkFlag', true)
            } else {
                commit('setHasWorkFlag', false)
            }
        }
    },
    personal = {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    };

export default personal
