import Layout from '@/views/layout/index.vue'
let aboutUsRouter = {
    path: '/group',
    component: Layout,
    redirect: '/group/index',
    meta: {
        title: '关于我们',
        // noCache: true
    },
    children: [
        {
            path: 'index',
            name: 'Group',
            component: () => import('@/views/group/index.vue'),
            meta: {
                title: '关于我们',
                // noCache: true
            }
        }
    ]
}


export default aboutUsRouter
