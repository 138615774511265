import { qqGroupList, aboutUsNavItem, enterpriseDataItem, registrationProcessItem } from './interface'
let personInfo: any = localStorage.getItem('singapore_personal_Data')
if (personInfo) {
    personInfo = JSON.parse(personInfo)
}
let qqGroupListData: Array<qqGroupList> = [
        {
            city: '合肥',
            list: [
                {
                    text: '新一年级QQ群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=06f9edbe0aedf556cbfd8253f1b70d78a19cd94636824239215425328a901b82'
                },
                {
                    text: '新二年级QQ群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=e0d9984f55f11ecfb810d5cecea47ae60607af5285926b32d9999e8a0ae857d1'
                },
                {
                    text: '新三年级QQ群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=9f31ec28f1eacf2e45a0bc823d86460747056875577c3802d8366bb1a9d57c3e'
                },
                {
                    text: '新四年级QQ群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=e646f8b6558316cfcb0c3e43ae1df6c9f6b8e69db278e12edaee95d1ca2a37fa'
                },
                {
                    text: '新五年级QQ群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=74e1b7e3b1ee01d0afd295008421dab893161b2908aa8077814fb8170c215407'
                },
                {
                    text: '新六年级qq群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=1dcf3a4d24d7485d996e3c8c88380f963b184c7d7e6be5ae598d637653b993e8'
                },
                {
                    text: '小升初QQ群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=3c07e3301655179377a1328fb636ce6a733f6781f9dde0eab9a9516b6a246f9a'
                },
                {
                    text: '新初二QQ群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=a921626cbeca8363bd669d0af3af88d6730ccdd99119aeb68207d50436ed1b6d'
                },
                {
                    text: '新初三QQ群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=a3671ac33dcd81005d6a68805186e063fd55f5daed28616d46ef50202877f9f7'
                },
                {
                    text: '新高一QQ群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=f6441aaf4ec683125b4ec85dc2739a9b76e3b9194ae73015b2ae54ba7b5cf16f'
                },
                {
                    text: '新高二QQ群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=0a57cdcd0c6b08c98e5a647d1a8a8d9eeec5ef0178cd7e6d9ad045802a55ca20'
                },
                {
                    text: '高考QQ群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=23bea902e74d83e36fba886701461e6655a9746c3fd04494ed4cfa02e0e3cc50'
                }
            ]
        },
        {
            city: '芜湖',
            list: [
                {
                    text: '芜湖幼升小交流群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=af33f3b86f7982b51eb8f1d3033659b99ecdb4700b120c1a986ae1b9ccfe753c'
                },
                {
                    text: '芜湖方田1年级群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=ac529ee7752b1720e0980510ec8d077acf29c67be66b5541b90cfd53839f2f8d'
                },
                {
                    text: '芜湖方田2年级群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=1a4da3951100397fe4873ed06d838d550ac635cd993e425a0fdea0c7956260f7'
                },
                {
                    text: '芜湖方田3年级群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=ed4f6959be21214f253b442b4bccb9f06df5ba14b84bfe913d72c6acbc6b3b81'
                },
                {
                    text: '芜湖方田4年级群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=024dbf2f380ae2e35aa0974fa539e152b0755ca7c569bc32a41ee93a0461f523'
                },
                {
                    text: '芜湖方田5年级群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=92a02917c66e89ee74e9bd679edc03cec38e3b07aef1f7a078924f27391b0197'
                },
                {
                    text: '芜湖方田6年级群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=40a313bb42e3b3306cc64b521efd3c5b2b611c5061efd532ebe81630962672b5'
                },
                {
                    text: '芜湖方田7年级群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=2a18a0704fdacfbb643a99a64d572782796c2fb01771432d0b42771fd9f11bbf'
                },
                {
                    text: '芜湖方田8年级群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=5b5b4608869edaae7f32d4e4833fef801805afce007232c5d3cedb9aa042bb01'
                },
                {
                    text: '芜湖方田牛娃群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=5730b2bc88256e6536e545487305c91d86b28ef0ec17c08edf21277b4b2a0172'
                }
            ]
        },
        {
            city: '马鞍山',
            list: [
                {
                    text: '方田马鞍山牛娃群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=77037764c3e0407c8f39d61c0cf267056d0754d87b3c716245b8f74d5cb6bf5d'
                }
            ]
        },
        {
            city: '安庆',
            list: [
                {
                    text: '新3年级学习群',
                    url: 'https://qm.qq.com/cgi-bin/qm/qr?k=qAy9OTGJTcWHyjtatijujbUFLdQTf1tH&jump_from=webapi'
                },
                {
                    text: '新4年级学习群',
                    url: 'https://qm.qq.com/cgi-bin/qm/qr?k=EMCSMqRQyrYDoFJgbLtCEElziaEoABWF&jump_from=webapi'
                },
                {
                    text: '新5年级学习群',
                    url: 'https://qm.qq.com/cgi-bin/qm/qr?k=HC9nLB6PjQpA9XPNSLj95stuTfRhDMBn&jump_from=webapi'
                },
                {
                    text: '新6年级学习群',
                    url: 'https://qm.qq.com/cgi-bin/qm/qr?k=DnIvfVahfNuCxrVDqnqb4ujB1wySeUC4&jump_from=webapi'
                },
                {
                    text: '小升初牛娃交流群',
                    url: 'https://qm.qq.com/cgi-bin/qm/qr?k=LV--nSRbBBDcl3sxQWZgW1kQ5KqzGeuL&jump_from=webapi'
                },
                {
                    text: '七升八牛娃交流群',
                    url: 'https://qm.qq.com/cgi-bin/qm/qr?k=zdLitEUX7owpmvxvslnKLK2nr6AJvZk4&jump_from=webapi'
                }
            ]
        },
        {
            city: '南京',
            list: [
                {
                    text: '南京小学牛娃群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=9cab817df0ecc61af014fb2a4f5dcc16272b0683175c25b81e50da11d51a835b'
                },
                {
                    text: '南京新初一牛娃群',
                    url: 'https://shang.qq.com/wpa/qunwpa?idkey=30473dacc829cdef153322fb79be61d1c024fcbe65538e50e568422bc7bbcfbe'
                }
            ]
        }
    ],
    enterpriseAboutData: Array<enterpriseDataItem> = [
        {
            question: '1、何为“方田”？',
            answer: ['“方田”二字，取自中国古代数学专著《九章算术》。“方田者，田之正也。诸田不等，以方为正，故曰方田”。“方”和“田”在古代指正方形和长方形。']
        },
        {
            question: '2、我们是谁？',
            answer: ['自2014年7月成立以来，方田教育立志用科技改变学习，致力于为6-18岁的中小学生提供高品质的线下及在线课外辅导。现已成为中国安徽最大的教育培训机构之一。']
        },
        {
            question: '3、我们在哪里？',
            answer: [
                '办公室地址：1 One North Crescent, Razer South East Asia HQ, Level 7, Singapore 138538',
                '校区地址（暂定）：bukit timah plaza 2F'
            ]
        },
        {
            question: '4、我们的优势？',
            answer: ['为实现对每个学生的精准教学，方田自成立之日起就不断通过技术创新帮助孩子提高学习效率。此外，方田还实行线下小班教学和在线辅导的教育服务体系，并通过“教学七步法”和随堂助教模式，把教学重心从“老师讲得好”转变为“学生学得会”，让每个学生都能得到因材施教的专业辅导。']
        }
    ],
    enterpriseTeachingData: Array<enterpriseDataItem> = [
        {
            question: '1、何为“方田”？',
            answer: [
                'PAIS 报告书',
                '“PAIS”即 Personal Artificial Intelligence Studysystem，是方田教育独创的一套面向全体学生的个性化智能学习系统。通过采集评测数据、添加指标、设计算法，智能化地对参与测评的每个学生提供专属的分析评价，并提供个性化的问题解决方案。报告对学生学习的不同模块以及每道题目的测评情况都会提供专业的个性化评语，帮助学生梳理自己每个模块和每道题目的得失，并为下阶段的学习提供可操性强的指导意见。学生和家长可通过“PAIS”报告书，明确自身定位及当前阶段学习情况。',
                '方田π',
                '“方田π”是方田教育独立研发、具有自主知识产权的教学辅助软件。其目的在于将传统的书山题海转变为数字化、标准化的课程内容，进而对教师和学生在教学过程中产生的有效数据分别进行多维度分析，真正达到精准教学和因材施教。方田π由系统管理后台、教师π、学生π、助教π 和家长π 构成。系统管理后台主要用于对教务、课程、题库、讲义等教学要素的管理，是教学能够正常进行的保障。',
                '天天练',
                '“天天练”是由方田教育独立研发设计的在线答题和作业系统，包含方田独立题库及在线答题功能。学生可以通过公众平台服务号“方田教育”中的“做作业”完成每日线上答题，并由老师进行批改。系统不仅可以巩固学生每天的学习成果，还能够记录知识点的学习轨迹及掌握情况，使学习中所有的薄弱环节都一目了然，真正做到智能练习。'
            ]
        },
        {
            question: '2、何为“QPSM 教学方案” ？',
            answer: [
                '“QPSM”教学方案 是方田众多骨干教师在多年的教学实践中，不断摸索修正后总结出来的一套科学、系统的教学体系。从学生认知世界的角度出发，科学地设计情景，并且按照学生认知问题的逻辑顺序设置问题、制造认知冲突，尽可能地去启发学生思考。',
                '“QPSM”教学过程简述',
                '学生拿到题目（question），遇到疑问（problem），老师引导学生寻找解决方案（solution），老师提炼特征总结模型（model）。这是一个多次循环的程序，老师会对最终问题进行逐层分解，设置成较易解决的小问题，帮助学生跨越“P→S”的难关。',
                '“QPSM”教学方案真正意义上解决了传统教学中“填鸭式”教学的弊端，避免学生出现思维僵化、被动学习，分析问题和解决问题能力不足、缺乏创造力等问题。'
            ]
        },
        {
            question: '3、如何做到精品教学？',
            answer: [
                '方田自创办以来，一直延续着小班精品教学的授课模式。小学的每个班级不超过20人、初中的每个班级不超过25人、高中的每个班级不超过30人。小班教学模式保证每个班的教学质量和每个学生的学习效果，保证老师对班上每个学生都能给予足够的关注。'
            ]
        },
        {
            question: '4、何为“教学七步法”？',
            answer: [
                '经过无数次的调整和改进，“方田教学七步法”于2018年正式适用到所有年级的所有班型。通过“课前小测、知识讲解、错题订正、课堂直播、课后小测、作业巩固、课程总结”七个步骤，学生不仅学习成绩得到了显著提升，而且学习习惯也得到了培养和巩固。'
            ]
        },
        {
            question: '5、“随堂助教”有哪些优势？',
            answer: [
                '方田的所有助教老师由优秀在校大学生组成，他们不仅喜爱孩子，热爱教育行业，而且具备良好的学习能力和个人品格。在课堂中，助教老师不仅承担着课前测和课后测的错题订正、作业情况的日常评价、课堂实况的微信群直播等工作，还会及时将学生的学习情况反馈给家长，并给出客观建议，以便帮助孩子及时改进。'
            ]
        },
        {
            question: '6、为什么开放家长旁听？',
            answer: [
                '（1）开放旁听，源于方田对课程内容和授课质量的足够自信。',
                '（2）方田的课程内容较学校有所区别，开放旁听可方便家长了解课程难易程度。',
                '（3）开放旁听可方便家长看到孩子的课堂表现，以便及早发现不足，解决问题。',
                '（4）开放旁听可方便家长获取更多学习方法和技巧，帮助孩子培养良好习惯。'
            ]
        }
    ],
    enterpriseTotalData: Array<aboutUsNavItem> = [
        {
            text: '16',
            compName: '校区'
        },
        {
            text: '100+',
            compName: '优秀教师'
        },
        {
            text: '25000+',
            compName: '就读学员'
        },
        {
            text: '10000+',
            compName: '获奖学院'
        }
    ],
    registrationProcess: registrationProcessItem = {
        title: '报名流程',
        list: [
            '预约测试(官网/电话)',
            '参加测试确认班型',
            '选择课程',
            '选择班级',
            '缴费(现金/刷卡/微信/支付宝)',
            '报名成功'
        ]
    },
    registrationWay: registrationProcessItem = {
        title: '报名方式',
        list: [
            '1.自助报名：登录方田教育官网：https://kangaroo.study，在选课中心，自助报名。',
            '2.微信报名：进入“方田教育”微信公众号，下方菜单栏中，点击“我要>看一看”，选择校区、年级、科目、课程类型，点击课程名称，选择校区、老师、年限，点击“报名课程”。',
            '3.电话报名：拨打方田教育各校区前台电话，进行报名。',
            '4.现场报名：到方田教育各校区前台进行现场报名。',
            '前台工作时间：寒暑假：周一至周日 早8: 00 - 晚20: 30；春秋季：周二至周五 下午14: 00 - 晚20: 30；周六至周日 早8: 00 - 晚20: 30'
        ]
    },
    payWay: registrationProcessItem = {
            title: '支付方式',
            list: [
                '方田教育课程缴费可以通过小程序自助微信支付，或是到各校区前台进行现场支付，支持现金、刷卡、微信、支付宝四种支付方式。课程报名但未支付，只能保留名额2小时，2小时后名额释放。'
            ]
        }
    ,
    refundNotice: registrationProcessItem = {
        title: '退费须知',
        list: [
            '各类邀请赛的报名费不予退费。 短期班、特殊优惠班级不予退费；长期班课次少于等于三次时，本期长期班不予退费。',
            '退费计算方式：用户应退金额 = 实际缴费金额 - 课程单价*系统发生课次。',
            `课程单价：120$新币/课次。`,
            '系统发生课次：以办理当日方田系统内发生课次为准。',
            '若因家长缴费途径有变（如微信、支付宝账号改变、银行卡注销、银行卡解绑等）而导致无法原路退回，需家长自行联系微信、支付宝、银行等有关方咨询退费方式，确保自己资金安全。',
            '若因为我方的第三方结算平台出现结算错误而无法实现原路退回的，则家长需至校区前台办理现金退费。',
            '办理退费时，必须在服务中心前台现场办理，同时必须出示本期班学员听课证。且必须接受方田工作人员电话核实办理退费人身份，不接受除校区前台外任何形式的口头预约退费。',
            '由于学校特殊原因取消班次时，报名金额全部退还。 由于学员自身原因缺课（学生考勤未到，系统中查询无调课转班记录），学校不予补课，不予退费。'
        ]
    },
    changingClasses: registrationProcessItem = {
        title: '调班转课',
        list: [
            '临时调课、永久转班仅限于同年份、同学期、同年级、同学科、同班型、总金额相同、总课次相同、剩余课次相同的班级之间互相办理。',
            '办理临时调课、永久转班时，必须携带原班学员听课证。 临时调课：必须在调入班级有可调名额的情况下才可办理，长期班每期最多办理3次，短期班每期最多办理1次。',
            '永久转班：必须在转入班级有名额的情况下才可办理，长期班每期最多办理3次，短期班每期最多办理1次。'
        ]
    },
    commonProblem: registrationProcessItem = {
        title: '常见问题',
        list: [
            '1.为什么要做入学测试',
            '非上一季在读学员，本季入学之前必须进行入学测试，目的主要是了解孩子学习情况，根据孩子现有水平来选择合适的班型，班型合适才能确保孩子最佳的学习效果。',
            '2.暑假（或寒假）的多期课程内容一样吗？',
            '暑假（或寒假）多期课程每期内容都是一样的，孩子只需要选择一期的课程即可。',
            '3.为什么要暑秋（寒春）连报？',
            '这样能够保证暑假上完了到秋季也有同样的班次可以上，不会出现没有名额的情况。对于小学来说，暑假内容和秋季内容是连贯的两部分知识，暑假不学习会导致到秋季后孩子知识上有缺失。对于初中来说，暑假初步和秋季的同步拓展是相辅相成的，暑假不学初步，秋季跟上同步拓展的课程有一定的困难。此外，秋季班时间实在调整不过来也可以选择退费。方田教育所有课程家长可以全程旁听，可以随时退费。'
        ]
    };
export {
    qqGroupListData,
    enterpriseAboutData,
    enterpriseTeachingData,
    enterpriseTotalData,
    registrationProcess,
    registrationWay,
    payWay,
    refundNotice,
    changingClasses,
    commonProblem
}
