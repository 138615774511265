import Layout from '@/views/layout/index.vue'
const infomationRouter = {
    path: '/infomation',
    component: Layout,
    redirect: '/infomation/index',
    meta: {
        title: '文章详情',
        // noCache: true
    },
    children: [
        {
            path: 'index',
            name: 'InfomationPage',
            component: () => import('@/views/infomation/index.vue'),
            meta: {
                title: '文章详情',
                // noCache: true
            }
        }
    ]
}


export default infomationRouter
