import { GetterTree } from 'vuex'
import { ActionContext, navItem } from './../interface'
import { Caxios } from './../../utils/axios'

export interface State {
    teacherNav: Array<navItem>;
    teacher: Array<any>;
    teacherId: number;
    teacherItemData: any;
    swiperTeachers: Array<any>;
    loadingFlag: boolean;
}

const state: State = {
        teacherNav: [
            {
                text: '数学老师',
                url: 'sx',
                iconFlag: false
            },
            // {
            //     text: '物理老师',
            //     url: '3',
            //     iconFlag: false
            // },
            // {
            //     text: '化学老师',
            //     url: '4',
            //     iconFlag: false
            // },
            // {
            //     text: '英语老师',
            //     url: '2',
            //     iconFlag: false
            // },
            {
                text: '华文老师',
                url: 'yw',
                iconFlag: false
            }
        ],
        teacher: [],
        teacherId: -1,
        teacherItemData: {},
        swiperTeachers: [],
        loadingFlag: true
    },
    getters: GetterTree<State, any> = {
        teacherNav: (state: State) => state.teacherNav,
        teacher: (state: State) => state.teacher,
        teacherId: (state: State) => state.teacherId,
        teacherItemData: (state: State) => state.teacherItemData,
        swiperTeachers: (state: State) => state.swiperTeachers,
        loadingFlag: (state: State) => state.loadingFlag
    },
    mutations = {
        setTeacher(state: State, teacher: Array<any>): void {
            state.teacher = teacher
        },
        setTeacherId(state: State, teacherId: number): void {
            state.teacherId = teacherId
        },
        setTeacherItemData(state: State, teacherItemData: any): void {
            state.teacherItemData = teacherItemData
        },
        setSwiperTeachers(state: State, swiperTeachers: Array<any>): void {
            state.swiperTeachers = swiperTeachers
        },
        setLoadingFlag(state: State, loadingFlag: boolean): void {
            state.loadingFlag = loadingFlag
        }
    },
    actions = {
        async setTeacher(context: ActionContext, teacher: any): Promise<void> {
            const { commit } = context
            commit('setTeacher', teacher)
        },
        async setTeacherItemData(context: ActionContext, teacherItemData: any): Promise<void> {
            const { commit } = context
            commit('setTeacherItemData', teacherItemData)
        },
        async setTeacherList(context: ActionContext, obj: any): Promise<void> {
            let { commit } = context,
                { type } = obj,
                qs: any = require('qs'),
                _data: any = {
                    teacherName:obj.name,
                    subjectCode:obj.subject,
                    gradeCode:obj.grade
                };
            delete _data.type
            if(!_data.name) {
                delete _data.name
            }
            if(!_data.grade) {
                delete _data.grade
            }
            commit('setLoadingFlag', true)
            const result: any = await Caxios.post({ url: '/api/official/home/showTeachers',
                data:_data })
            if(result.code !== '10000')return
                // swiperName: string = "曹克楠,田峰,曹媛,许宏远,汪浚源,俞堃,樊凯,陈波,文怡,郭璟,王浩川,李妍,王宇梁,夏泉升,陶张宁";
            commit('setTeacher', result.data)
            commit('setLoadingFlag', false)
            console.log(result, '返回老师')
            if(type == 0) {
                const _swiperArr: Array<any> = []
                if(result.length > 0) {
                    /*result.forEach((item: any): void => {
                        let _name = ''
                        if(item.name.indexOf('_pre') >= 0) {
                            _name = item.name.split('_')[0]
                        } else {
                            _name = item.name
                        }
                        if(swiperName.indexOf(_name) >= 0) {
                            _swiperArr.push(item)
                        }
                    })*/
                }
                commit('setSwiperTeachers', result.data)
            }
        }
    },
    teacher = {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    };

export default teacher
