
    import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
    import { secretKey } from './../../utils/secretKey'
    import { mapGetters } from 'vuex'


    let eyeClose = require('./../../assets/imgs/eye_close.png'),
        eyeOpen = require('./../../assets/imgs/eye_open.png');
    @Component({
        name: 'UsePwd',
        computed: mapGetters('personal', ['attrEmptyFlag'])
    })
    export default class UsePwd extends Vue {
        attrEmptyFlag!: number
        @Prop() placeholder!: string
        @Prop() parent!: string
        private type: string = 'password'
        private password: string = ''
        private eyeClose: any = eyeClose
        private eyeOpen: any = eyeOpen

        private status: number = 0

        private changeType (): void {
            this.type = this.type == 'password' ? 'text' : 'password'
        }
        private focusEvent(): void {
            this.status = 0
            this.usepwdCb(0)
        }
        @Watch('attrEmptyFlag', { immediate: true, deep: true })
        attrEmptyFlagWatch(newVal: number): void {
            let _this: any = this
            if(_this.$route.path == '/personal/index') {
                this.password = ''
            }
        }
        @Emit()
        private usepwdCb(status: number): any {
            return { password: this.password, status: status }
        }
        private blurEvent (): void {
            if(this.password) {
                this.status = 1
            } else {
                this.status = 2
            }
            this.usepwdCb(1)
        }
        beforeMount() {
            let _this: any = this
            if (_this.$route.path !== '/personal/index') {
                let _user: any = localStorage.getItem('fangtian_pwd')
                if(_user && this.parent == '2') {
                    this.password = secretKey(_user, 'password', 'decryption')
                }
            }
        }
    }
