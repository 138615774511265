import Layout from '@/views/layout/index.vue'
let personalRouter = {
    path: '/personal',
    component: Layout,
    redirect: '/personal/index',
    meta: {
        title: '个人中心',
        // noCache: true
    },
    children: [
        {
            path: 'index',
            name: 'Personal',
            component: () => import('@/views/personal/index.vue'),
            meta: {
                title: '个人中心',
                // noCache: true
            }
        }
    ]
}


export default personalRouter