<template>
  <div class="confirm" v-if="isShow">
    <div class="confirm-content" :style="{ width: options.width }">
      <div class="confirm-header">
        <span class="title">{{ options.title }}</span>
        <span class="cur pos-r close" @click="cancel"></span>
      </div>
      <div class="confirm-body">
        <div class="sub-title" v-if="options.subTitle">
          <span class="error-icon" v-if="options.icon === 'error'">!</span><span>{{ options.subTitle }}</span>
        </div>
        <ul class="lists" v-if="options.lists">
          <li v-for="(item, index) in options.lists" :key="index" v-html="item"></li>
        </ul>
        <div v-if="options.input">
          <input v-model="text" />
        </div>
        <div class="btns-wrapper">
          <span class="sure" @click="sure()" v-if="options.btns.sure">{{
            options.btns.sure
          }}</span>
          <span class="cancel" @click="cancel()" v-if="options.btns.cancel">{{
            options.btns.cancel
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Confirm",
  props: ["options", "isShow"],
  data() {
    return {
      text: "",
    };
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.confirm {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);

  .confirm-content {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 4px;

    .confirm-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 14px;
      height: 30px;
      //height: 40px;
      //background: #f8f8f8;
      border-radius: 4px 4px 0 0;

      .title {
      }

      .close {
        width: 15px;
        height: 15px;

        &:before, &:after {
          content: '';
          position: absolute;
          top: 7px;
          left: 0;
          width: 100%;
          height: 1px;
          background: #2d2c3b;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }

    .confirm-body {
      //padding: 24px 14px;
      padding: 4px 14px 24px;
      .sub-title {
        display:flex;
        align-items center;
        line-height: 20px;
        color: #606266;
        .error-icon {
          display block
          width 20px
          height 20px
          border-radius 10px
          color #ffffff
          font-weight bolder
          background #F59A23
          line-height 20px
          text-align center
          margin-right 5px
        }
      }
      .lists {
        li {
          color: #606266;
          line-height: 32px;
          border-bottom: 1px solid #ddd;
        }
      }

      .btns-wrapper {
        display: flex;
        align-items: center;
        //justify-content: flex-end;
        justify-content: center;
        margin-top: 16px;

        span {
          padding: 0 10px;
          text-align: center;
          min-width: 70px;
          line-height: 36px;
          border: 1px solid #ddd;
          cursor: pointer;
          border-radius: 4px;

          &:not(:last-child) {
            margin-right: 12px;
          }

          &.sure {
            //background: #007ab0;
            //color: #fff;
            color: #409EFF;
            background: #ffffff;
            &:hover {
              //background: rgba(0, 122, 176, 0.8);
            }
          }

          &.cancel {
            &:hover {
              color: #007ab0;
              border-color: #007ab0;
              background: #e7f5fb;
            }
          }
        }
      }
    }
  }
}
</style>
