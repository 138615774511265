/*
 * @Author: fremember
 * @Date: 2021-01-14 09:29:24
 * @Description:
 */
import Vue from "vue";
import VueRouter, { RouteConfig, RawLocation, Route } from "vue-router";

import commonRouter from "./modules/common";
import dashboardRouter from "./modules/dashboard";
import courseRouter from "./modules/course";
import aboutUsRouter from "./modules/aboutUs";
import aboutRouter from "./modules/about";
import groupRouter from "./modules/group";
import personalRouter from "./modules/personal";
import infomationRouter from "./modules/infomation";
import nearbyCampusRouter from "./modules/nearbyCampus";

Vue.use(VueRouter);

export const constantRoutes: Array<RouteConfig> = [
  ...commonRouter,
  dashboardRouter,
  courseRouter,
  aboutRouter,
  aboutUsRouter,
  personalRouter,
  nearbyCampusRouter,
  groupRouter,
  infomationRouter,
];
// export const asyncRoutes: Array<RouteConfig> = [
//     dashboardRouter,
// ]
/**
 * 项目中报错 Error: Avoided redundant navigation to current location: “/xxx”. 的解决方案
 */
const originalPush = VueRouter.prototype.push as unknown as Promise<Route>;
VueRouter.prototype.push = function push(location: RawLocation) {
  return (originalPush as any).call(this, location).catch((err: Error) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE,
  routes: constantRoutes
});
export default router;
