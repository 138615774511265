/*
 * @Author: fremember
 * @Date: 2021-02-24 11:13:13
 * @Description: 
 */
import Layout from '@/views/layout/index.vue'
let nearbyCampusRouter = {
    path: '/nearbyCampus',
    component: Layout,
    redirect: '/nearbyCampus/index',
    meta: {
        title: '附近校区',
        // noCache: true
    },
    children: [
        {
            path: 'index',
            name: 'NearbyCampus',
            component: () => import('@/views/nearbyCampus/index.vue'),
            meta: {
                title: '个人中心',
                // noCache: true
            }
        }
    ]
}


export default nearbyCampusRouter