/*
 * @Author: your name
 * @Date: 2020-12-21 16:51:31
 * @LastEditTime: 2021-02-02 15:20:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /projectRootSource/gitblit/new_website/src/router/modules/course.ts
 */
import Layout from '@/views/layout/index.vue'
let courseRouter = {
    path: '/course',
    component: Layout,
    redirect: '/course/index',
    meta: {
        title: '选课中心',
        // noCache: true
    },
    children: [
        {
            path: 'index',
            name: 'Course',
            component: () => import('@/views/course/index.vue'),
            meta: {
                title: '选课中心',
                // noCache: true
            }
        },
        {
            path: 'detail',
            name: 'CourseDetail',
            component: () => import ('@/views/course/detail/index.vue'),
            meta: {
                title: '选课详情',
                // noCache: true
            }
        }
    ]
}


export default courseRouter