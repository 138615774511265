
    import { Component, Vue } from 'vue-property-decorator'
    import { mapGetters, mapMutations } from 'vuex'

    @Component({
        name: 'CopyRight',
        computed: {
            ...mapGetters('common', ['navList']),
            curNavList () {
                const nav: any = [
                    {
                        text: this.$t('common.nav.shouye'),
                        url: '/dashboard/index',
                        iconFlag: false,
                        componentName: ''
                    },
                    {
                        text: this.$t('common.nav.xuankezhongxin'),
                        url: '/course/index',
                        iconFlag: false,
                        componentName: ''
                    },
                    {
                        text: this.$t('common.nav.jiaoliuzhongxin'),
                        url: 'http://bbs.kangaroo.study',
                        iconFlag: false,
                        componentName: ''
                    },
                    {
                        text: this.$t('common.nav.yinsizhengce'),
                        url: '',
                        iconFlag: false,
                        componentName: 'PrivacyPolicy'
                    },
                    {
                        text: this.$t('common.nav.guanyuwomen'),
                        url: '/aboutUs/index',
                        iconFlag: false,
                        componentName: ''
                    },
                    {
                        text: this.$t('common.nav.kehuduanxiazai'),
                        url: '',
                        iconFlag: true,
                        componentName: ''
                    }/*,
                    {
                        text: this.$t('common.nav.xiaozhangxinxiang'),
                        url: '',
                        iconFlag: false,
                        componentName: 'HeadmasterMailbox'
                    }*/,
                    {
                        text: this.$t('common.nav.jiaruwomen'),
                        url: 'https://job.fangtian.me/',
                        iconFlag: false,
                        componentName: ''
                    }
                ]
                return nav.filter((v: { text: string }) => v.text != '客户端下载')
            }
        },
        methods: {
            ...mapMutations('common', ['setGoToTopFlag', 'setCommonComponentName'])
        }
    })
    export default class CopyRight extends Vue {
        navList!: Array<any>
        setGoToTopFlag!: any
        setCommonComponentName!: any

        // private curNavList: Array<any> = []

        private timer: any = null

        private changeRoute (item: any): void {
            if(!item.componentName) {
                if(item.url.indexOf('http://') >= 0 || item.url.indexOf('https://') >= 0) {
                    window.open(item.url)
                } else {
                    let _this: any = this
                    if(_this.$route.path == item.url) {
                        this.setGoToTopFlag(true)
                    } else {
                        _this.$router.push(item.url)
                    }
                }
            } else {
                this.setCommonComponentName(item.componentName)
            }
        }

        beforeMount() {
            // this.curNavList = [ ...this.navList ].filter(v => v.text != '客户端下载')
        }
    }
