export default {
    'course.t1': '所选条件',
    'course.t2': '无',
    'course.t3': '全部清除',
    'course.t4': '选择年份',
    'course.t5': '选择年级',
    'course.t6': '选择学科',
    'course.t7': '暂无数据',
    'course.t8': '已选条件',
    'course.t9': '班型',
    'course.t10': '您当前的测试级别',
    'course.t11': '请选择班型',
    'course.t12': '名额已满',
    'course.t13': '校区',
    'course.t14': '请选择校区',
    'course.t15': '老师',
    'course.t16': '请选择老师',
    'course.t17': '剩余名额',
    'course.t18': '请选择上课时间',
    'course.t19': '日期',
    'course.t20': '火热报名',
    'course.t21': '名额紧张',
    'course.t22': '课程介绍',
    'course.t23': '任课老师',
    'course.t24': '授课特点',
    'course.t25': '教学宣言',
    'course.t26': '方田集体入学测试（免费）',
    'course.t27': '频率',
    'course.t28': '课次',
    'course.t29': '时间',
    'course.t30': '教室',
    'course.t31': '报名',
    'course.t32': '注：请根据测试级别报名课程',
    'course.t33': '免费预约诊断测试！',
    'course.t34': '热门课程',
    'course.t35': '选择分校',
    'course.t36': '修改',
    'course.t37': '无',
    'course.t38': '无',
    'course.t39': '无',
    'course.t40': '无',
    'course.t41': '无',
    'course.t42': '无',
    'course.t43': '无',
    'course.t44': '无',
    'course.t45': '无',
    'course.t46': '无',
    'course.t47': '无',
    'course.t48': '无',
    'course.t49': '无',
}
