/*
 * @,@Author: ,: your name
 * @,@Date: ,: 2021-01-22 18:50:13
 * @,@LastEditTime: ,: 2021-03-04 16:17:48
 * @,@LastEditors: ,: Please set LastEditors
 * @,@Description: ,: In User Settings Edit
 * @,@FilePath: ,: \codes\new_website\src\store\module\personalTtl.ts
 */
import { GetterTree } from 'vuex'
import { ActionContext } from './../interface'
import { Caxios } from './../../utils/axios'
export interface State {
    practiceList: Array<any>;
    practiceItems: Array<any>;
}

let state: State = {
    practiceList: [],
    practiceItems: []
},
    getters: GetterTree<State, any> = {
        practiceList: (state: State) => state.practiceList,
        practiceItems: (state: State) => state.practiceItems,
    },
    mutations = {
        setPracticeList(state: State, practiceList: Array<any>): void {
            state.practiceList = practiceList
        },
        setPracticeItems(state: State, obj: any): void {
            let { arr, idx, type } = obj
            if (type == 0) {
                state.practiceItems = arr
            } else {
                if (arr.length > 0) {
                    state.practiceItems[idx] = arr[0]
                }
            }
        }
    },
    actions = {
        async setPracticeList(context: ActionContext, obj: any) {
            let { commit, dispatch } = context,
                { stdid, pagetype } = obj,
                result: any = await Caxios.get({ url: `/api/${stdid}/tasks?page=1&limit=1000&pagetype=${pagetype}&stdid=${stdid}` });
            commit('setPracticeList', result.records)
        },
        async setPracticeItems(context: ActionContext, obj: any) {
            let { commit } = context,
                { id, idx } = obj,
                result: any = await Caxios.get({ url: `/api/questions?ids=${id}` });
            if (result && Array.isArray(result) && result.length > 0) {
                commit('setPracticeItems', { arr: result, idx: idx, type: 1 })
            } else {
                commit('setPracticeItems', { arr: [], idx: idx, type: 1 })
            }
        }
    },
    personalTtl = {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    };

export default personalTtl