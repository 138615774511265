import { GetterTree } from 'vuex'
import { ActionContext } from './../interface'
import { Caxios } from './../../utils/axios'
export interface State {
    collectionList: Array<any>;
    collectionItems: Array<any>;
}

let state: State = {
    collectionList: [],
    collectionItems: []
},
    getters: GetterTree<State, any> = {
        collectionList: (state: State) => state.collectionList,
        collectionItems: (state: State) => state.collectionItems,
    },
    mutations = {
        setCollectionList(state: State, collectionList: Array<any>): void {
            state.collectionList = [ ...collectionList ]
        },
        setCollectionItems(state: State, obj: any): void {
            let { arr, idx, type } = obj
            if (type == 0) {
                state.collectionItems = arr
            } else {
                if (arr.length > 0) {
                    state.collectionItems[idx] = arr[0]
                }
            }
        }
    },
    actions = {
        async setCollectionList(context: ActionContext, obj: any) {
            
            let { commit, dispatch } = context,
                { stdid, type } = obj;
            commit('setCollectionList', [])
            let result: any = await Caxios.get({ url: `/api/${stdid}/notes?page=1&limit=1000&type=${type}&stdid=${stdid}` });

            commit('setCollectionList', result.records)
        },
        async setCollectionItems(context: ActionContext, obj: any) {
            let { commit } = context,
                { id, idx } = obj,
                result: any = await Caxios.get({ url: `/api/questions?ids=${id}` });
            if (result && Array.isArray(result)) {
                commit('setCollectionItems', { arr: result, idx: idx, type: 1 })
            } else {
                commit('setCollectionItems', { arr: [], idx: idx, type: 1 })
            }
        }
    },
    personalCollection = {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    };

export default personalCollection