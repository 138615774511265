export default {
    'common.nav.shouye': 'HOME',
    'common.nav.xuankezhongxin': 'CURRICULUM',
    'common.nav.jiaoliuzhongxin': 'INTERFLOW',
    'common.nav.guanyuwomen': 'ABOUT ME',
    'common.nav.kehuduanxiazai': 'CLIENT',
    'common.nav.gerenzhongxin': 'PERSONAL',
    'common.nav.tuichu': 'Logout',
    'common.nav.downloadApp': 'Scan to download App',
    'common.nav.denglu': 'Log in',
    'common.nav.zhuce': 'Register',
    'common.nav.xiaozhangxinxiang': 'PRINCIPAL‘S MAILBOX',
    'common.nav.jiaruwomen': 'JOIN US',
    'common.nav.yinsizhengce': 'PRIVACY POLICY',
    'common.nav.wangjimima': 'Forget your password?',
    'common.nav.xuanzechengshi': 'Please select your city',
    'common.t1': 'Please enter username',
    'common.t2': 'Please enter password',
    'common.t3': 'Remember the password',
    'common.t4': 'No account',
    'common.t5': 'register now',
    'common.t6': 'Incorrect mobile number format',
    'common.t7': 'Password cannot be empty',
    'common.t8': 'Successful Login',
    'common.t9': 'Please select a branch school',
    'common.t10': 'Mobile number',
    'common.t11': 'Verification Code',
    'common.t12': 'New password (8-12 digits)',
    'common.t13': 'Name',
    'common.t14': 'Male',
    'common.t15': 'Female',
    'common.t16': 'Please select a grade',
    'common.t17': 'User Registration Agreement',
    'common.t18': 'I agree to the',
    'common.t19': 'Name cannot be empty',
    'common.t20': 'Wrong password format',
    'common.t21': 'Password cannot be empty',
    'common.t22': 'Verification code cannot be empty',
    'common.t23': 'Grade cannot be empty',
    'common.t24': 'Mobile number cannot be empty',
    'common.t25': 'Please read and agree to the User Agreement before registering',
    'common.t26': 'registration success',
    'common.t27': 'New password (8-12 digits)',
    'common.t28': 'Confirmation of password',
    'common.t29': 'SUBMIT',
    'common.t30': 'Send',
    'common.t31': 'Resend',
    'common.t32': 'The two password inputs are inconsistent',
    'common.t33': 'Password reset complete',
    'common.t34': 'Please select',
    'common.t35': 'Entry of father\'s occupation information',
    'common.t36': 'Please select',
    'common.t37': 'Math',
    'common.t38': 'Chinese',
    'common.t39': 'Please select',
    'common.nav.chooseStudent': 'Please select a student',
    'common.nav.switchStudent': 'Please select the student to switch',
    'common.nav.cancel': 'Cancel',
    'common.nav.confirm': 'Confirm',
    'common.message.success':'Switch successful'
}
