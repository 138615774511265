
    import { Component,Watch, Vue } from 'vue-property-decorator'
    import {
        mapMutations
    } from 'vuex'
    import i18n from "@/locales";

    @Component({
        name: 'App',
        methods: {
            ...mapMutations('common', ['setNavList'])
        }
    })
    export default class App extends Vue {
        setNavList!: any
         
        mounted() {
            const nav: any = [
                {
                    text: this.$t('common.nav.shouye'),
                    url: '/dashboard/index',
                    iconFlag: false,
                    componentName: ''
                },
                {
                    text: this.$t('common.nav.xuankezhongxin'),
                    url: '/course/index',
                    iconFlag: false,
                    componentName: ''
                },
                {
                    text: this.$t('common.nav.jiaoliuzhongxin'),
                    url: 'http://bbs.kangaroo.study',
                    iconFlag: false,
                    componentName: ''
                },
                {
                    text: '隐私政策',
                    url: '',
                    iconFlag: false,
                    componentName: 'PrivacyPolicy'
                },
                {
                    text: this.$t('common.nav.guanyuwomen'),
                    url: '/aboutUs/index',
                    iconFlag: false,
                    componentName: ''
                },
                {
                    text: this.$t('common.nav.kehuduanxiazai'),
                    url: '',
                    iconFlag: true,
                    componentName: ''
                }/*,
                {
                    text: '校长信箱',
                    url: '',
                    iconFlag: false,
                    componentName: 'HeadmasterMailbox'
                }*/,
                {
                    text: '加入我们',
                    url: 'https://job.fangtian.me/',
                    iconFlag: false,
                    componentName: ''
                }
            ]
            this.setNavList(nav)
        }
    }
