import { GetterTree } from 'vuex'
import { ActionContext } from './../interface'
import { Caxios } from './../../utils/axios'
export interface State {
    kscfList: Array<any>;
    kscfItems: Array<any>;
}

let state: State = {
        kscfList: [],
        kscfItems: []
    },
    getters: GetterTree<State, any> = {
        kscfList: (state: State) => state.kscfList,
        kscfItems: (state: State) => state.kscfItems
    },
    mutations = {
        setKscfList(state: State, kscfList: Array<any>): void {
            state.kscfList = kscfList
        },
        setKscfItems(state: State, kscfItems: Array<any>): void {
            state.kscfItems = kscfItems
        },
    },
    actions = {
        async setKscfList(context: ActionContext, obj: any) {
            let { commit, dispatch } = context,
                { name, phone } = obj,
                result: any = await Caxios.get({ url: `/api/exams/${name}/${phone}?page=1&limit=100`});
            if (result && Array.isArray(result.records)) {
                commit('setKscfList', result.records)
            } else {
                commit('setKscfList', [])
            }
        },
        async setKscfItem(context: ActionContext, obj: any) {
            let { commit, dispatch } = context,
                { name, phone, id } = obj,
                result: any = await Caxios.get({ url: `/api/${id}/exams_record/${name}/${phone}` });
            if (result && Array.isArray(result.list)) {
                commit('setKscfItems', result.list)
            } else {
                commit('setKscfItems', [])
            }
        }
    },
    personalKscf = {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    };

export default personalKscf