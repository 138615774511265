
    import { Component, Vue } from 'vue-property-decorator'
    import { mapGetters, mapMutations } from 'vuex'
    @Component({
        name: 'FooterView',
        methods: {
            ...mapMutations('common', ['setCommonComponentName'])
        }
    })
    export default class FooterView extends Vue {
        setCommonComponentName!: any
        private showModal (componentName: any): void {
            this.setCommonComponentName(componentName)
        }
    }
