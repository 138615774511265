
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import { mapMutations, mapActions } from 'vuex'
    import { loginInter } from './../../store/interface'
    import { checkPhone, checkSPhone } from './../../utils/validate'
    import { secretKey } from './../../utils/secretKey'

    import UseName from './../username/index.vue'
    import UsePwd from './../password/index.vue'

    import { Caxios } from './../../utils/axios'


    @Component({
        name: 'MineLogin',
        components: { UseName, UsePwd },
        methods: {
            ...mapMutations('common', ['setCommonComponentName', 'lang']),
            ...mapActions('login', ['loginEvent']),
            ...mapActions('personal', ['setPersonalData']),
            ...mapMutations('common', ['setLogin'])
        }
    })
    export default class MineLogin extends Vue {
        setCommonComponentName!: any
        setLogin!: any
        loginEvent!: any
        lang!: any
        setPersonalData!: any
        private loginForm: loginInter = {
            phone: '',
            password: ''
        }
        private hasSelected = false
        private usernameMsg: any = ''
        private passwordMsg: any = ''

        // @Watch('loginForm', { immediate: true, deep: true })
        // loginFormWatvh(newVal: loginInter): void {
        //     if(newVal.phone && newVal.password) {
        //         this.loginEvent(newVal)
        //     }
        // }
        private usenameCb (obj: any): void {
            const { phone, status } = obj
            // this.loginForm.phone = phone
           /* if(checkSPhone(phone)) {
                this.usernameMsg = ''
                this.loginForm.phone = phone
            } else {
                // this.usernameMsg = this.$t('common.t6')
                this.loginForm.phone = ''
            }*/
            this.usernameMsg = ''
            this.loginForm.phone = phone
            if(status == 0) {
                this.usernameMsg = ''
            }
        }
        private usepwdCb (obj: any): void {
            const { password, status } = obj
            this.loginForm.password = password
            if(password) {
                this.passwordMsg = ''
            } else {
                this.passwordMsg = this.$t('common.t7')
            }
            if(status == 0) {
                this.passwordMsg = ''
            }
        }
        private async curLoginEvent () {
            if(!this.usernameMsg && !this.passwordMsg) {
              console.log('denglu',this.loginForm)
              const temp = {
                loginName:this.loginForm.phone,
                password:this.loginForm.password
              }
                const _result: any = await Caxios.post({ url: '/api/official/user/login', data: temp }),
                    _this: any = this;
              console.log('_result',_result)
                if(_result.code === '10000') {
                    this.setCommonComponentName('')
                    this.setLogin(true)
                    _this.$message.success(this.$t('common.t8'))
                    localStorage.setItem('singapore_phone', this.loginForm.phone)
                    localStorage.setItem('singapore_branchSchoolId', String(_result.data.branchSchoolCode))
                    if(this.hasSelected) {
                        localStorage.setItem('fangtian_user', secretKey(this.loginForm.phone, 'phone', 'encryption'))
                        localStorage.setItem('fangtian_pwd', secretKey(this.loginForm.password, 'password', 'encryption'))
                    } else {
                        this.removeItems()
                    }
                  /*  if(_result.data.father_job === "请选择" && this.lang === 'en-US') {
                        _result.data['father_job'] = "Please select"
                    }
                    if(_result.data.mother_job === "请选择" && this.lang === 'en-US') {
                        _result.data['mother_job'] = "Please select"
                    }*/
                    // 登录成功以后 - 存储TOKEN
                    localStorage.setItem('singapore_token', _result.data.token)

                    this.setPersonalData(_result.data)

                    localStorage.setItem('singapore_personal_Data', JSON.stringify(_result.data))
                    /*const path = this.$route.path
                    if (path.indexOf('/course/detail') >= 0) {
                        this.$router.go(-1)
                    } else {
                       this.$router.go(0)
                    }*/
                } else {
                    _this.$message.warning(this.lang === 'en-US' ? 'Incorrect username or password' : _result.msg)
                }
            }
        }
        private forgetEvent (): void {
            this.setCommonComponentName('MineForget')
            this.removeItems()
        }
        private signupEvent (): void {
            this.setCommonComponentName('MineRegister')
            this.removeItems()
        }
        private removeItems (): void {
            localStorage.removeItem('fangtian_user')
            localStorage.removeItem('fangtian_pwd')
        }
        beforeMount() {
            if(localStorage.getItem('fangtian_user') && localStorage.getItem('fangtian_pwd')) {
                this.hasSelected = true
                this.loginForm.phone = secretKey(localStorage.getItem('fangtian_user'), 'phone', 'decryption')
                this.loginForm.password = secretKey(localStorage.getItem('fangtian_pwd'), 'password', 'decryption')
            } else {
                this.hasSelected = false
            }
        }
    }
