export default {
    'home.c1.t1': 'High-quality Teaching',
    'home.c1.t2': 'Focus on the key point,and students will be much better',
    'home.c1.t3': 'Teaching',
    'home.c1.t4': 'Interactive Courseware',
    'home.c1.t5': 'The lower grades of primary school use interactive courseware with colorful styles that make children fall in love with learning',
    'home.c1.t6': 'Small-class Teaching',
    'home.c1.t7': 'The class size is around 20 people,ensuring that each child has a good learning environment',
    'home.c1.t8': 'Learning',
    'home.c1.t9': 'Parents Accompany',
    'home.c1.t10': 'Parents are allowed to enter the classroom to obse- rve and grow up with their children if they meet the requirements',
    'home.c1.t11': 'Face-to-face Teaching',
    'home.c1.t12': 'Offline tutoring,face-to-face communication with teachers,can close the distance with teachers',
    'home.c1.t13': 'Practice',
    'home.c1.t14': 'Mistake Book',
    'home.c1.t15': 'Record the child’s wrong questions,check them at any time,and consolidate the knowledge points by recording their own problems',
    'home.c1.t16': 'Day-day Practice',
    'home.c1.t17': 'One question per day to consolidate the knowledge learned in class and help students review their own class content',
    'home.c2.t1': 'Study with teacher‘ accompany.Seven steps to ensure teaching efficiency',
    'home.c2.t2': '7-step',
    'home.c2.t3': 'pedagogy',
    'home.c2.t4': 'Before-class Test',
    'home.c2.t5': 'Use the 20 minutes before the class to review the main points of the previous lesson',
    'home.c2.t6': 'Knowledge Explanation',
    'home.c2.t7': 'Teach the knowledge points of this lesson in the form of example practice and explanations',
    'home.c2.t8': 'Mistakes',
    'home.c2.t9': 'Use recess to explain and correct the wrong questions in this pre-class test',
    'home.c2.t10': 'Class Livestreaming',
    'home.c2.t11': 'The teaching assistant teacher will broadcast the lesson content live in the class group with pictures and word description',
    'home.c2.t12': 'After-Class Test',
    'home.c2.t13': 'Use the 20 minutes after class to practice the main points of knowledge in class',
    'home.c2.t14': 'Effective Homework',
    'home.c2.t15': 'After the class,the knowledge points of this lesson are reinforced and practiced',
    'home.c2.t16': 'Course Summary',
    'home.c2.t17': 'Generate a dedicated grade report for each student through Big Data',
    'home.c3.t1': 'Stratified Teaching to make higher efficiency',
    'home.c3.t2': 'Foundation',
    'home.c3.t3': 'Focus on laying a solid foundation to ensure that simple questions do not lose points',
    'home.c3.t4': 'Enhancement',
    'home.c3.t5': 'Focus on the re-improvement of the basics',
    'home.c3.t6': 'Creation',
    'home.c3.t7': 'Focus on the learning and exploration of mathematical problems',
    'home.c3.t8': 'Exploration',
    'home.c3.t9': 'Pay attention to the study of the difficulty topics of the national finals',
    'home.c3.t10': 'Fangtian',
    'home.c3.t11': 'Learn and master the types of questions which are as difficult as the questions of National High School Math League, CMO, IMO',
    'home.c4.t1': 'Good Teaching Research',
    'home.c4.t2': 'Making books independently. (Many of the questions have appeared in High School Entrance Examination)',
    'home.c4.t3': 'Accurate Prediction',
    'home.c4.t4': 'Many times of accurate prediction',
    'home.c4.t5': 'Fang Tian’s handouts have repeatedly predicted questions of high school entrance examination, the mid-term and final examination of famous school, and No. 45 Middle Schools,No. 42 Middle School, ShouChun Middle School, Fang Tian’s handouts have repeatedly predicted questions of high school entrance examination, the mid-term and final examination of famous school, and No. 45 Middle Schools, No. 50 Middle Schools, No. 42 Middle School, ShouChun Middle School, No. 1 Middle School, No. 6 Middle School, No. 8 Middle School, 168 Middle School and other middle and high schools Questions',
    'home.c4.t6': 'Every time students perfectly miss the questions they had done. The type of questions in the exam was very rare',
    'home.c4.t7': 'Nice Question Picking',
    'home.c4.t8': 'Independent Books Research',
    'home.c4.t9': 'The teaching materials used by Fangtian are self-developed handouts, and the teaching and research system formulated by the teacher after layers of review conforms to the students’ cognitive laws and the rules of examination propositions. And at the same time implements hierarchical teaching, and uses different levels of handouts according to the class type.',
    'home.c4.t10': 'Students have done many questions, but their effect of raising points is poor, and the effect of the sea of questions tactics is getting worse and worse',
    'home.c4.t11': 'Modularization',
    'home.c4.t12': 'Solution of A Type of Questions',
    'home.c4.t13': 'The example questions selected by FangTian in each lesson are all centered on theknowledge points, and the difficulty is progressive. Not only allowing students to understand the knowledge points but also ensuring that students will solve each problem and each type of problem. Then through the pre-class test and post-class test, the knowledge points are continuously consolidated.',
    'home.c4.t14': 'After being changed into another form, the same question cannot be done, and repetition of the same question will always lose points',
    'home.c5.t1': 'Top Students are back! Who can compete with students in Fangtian?',
    'home.c5.t2': '2020 FangTian Single Subject High Score List',
    'home.c5.t3': 'Mathematics',
    'home.c5.t4': 'There are 153 people with a full score in a single subject, accounting for 12.07%',
    'home.c5.t5': 'Full marks are 150；',
    'home.c5.t6': '222 people’s scores are between 145 to 150 points；',
    'home.c5.t7': '156 people’s scores are between 140 to 144 points；',
    'home.c5.t8': 'Students with a score of 140 points or more in a single subject accounted for 41.88%；',
    'home.c5.t9': 'Physics',
    'home.c5.t10': 'There were 117 people with a full score in a single subject, accounting for 30.47%',
    'home.c5.t11': 'Full marks are 70；',
    'home.c5.t12': '268 people’s scores in Physics are between 65 to 69；',
    'home.c5.t13': '95 people’s scores in Physics are between 60 to 64；',
    'home.c5.t14': 'Students with a score of 60 or more in a single subject accounted for 57.38%；',
    'home.c5.t15': 'Chemistry',
    'home.c5.t16': 'There are 153 people with a full score in a single subject, accounting for 12.07%',
    'home.c6.t1': 'They are standing behind the top students, teaching them with their hard work',
    'home.c7.t1': 'Let extraordinary people enter Education Industry',
    'home.c7.t2': 'Resume Delivery',
    'home.c7.t3': '80% Screening Rate',
    'home.c7.t4': 'Top students are sele- cted from key univer- sities',
    'home.c7.t5': 'First Round Test',
    'home.c7.t6': '60%Passing Rate',
    'home.c7.t7': 'Choose the suitable p- eople from the top st- udents',
    'home.c7.t8': 'Reexamine',
    'home.c7.t9': '30%Passing Rate',
    'home.c7.t10': 'Ability and quality are paid great importance o at t- he same time',
    'home.c7.t11': 'Training (7 days)',
    'home.c7.t12': '15%Passing Rate',
    'home.c7.t13': 'Love and responsibili- ty are Fangtian’s unc- hanging original inte- ntions',
    'home.c7.t14': 'Internship (3 months)',
    'home.c7.t15': '5%Passing Rate',
    'home.c7.t16': 'Make sure that each teacher has no shor- tcomings',
    'home.c7.t17': 'Final Test',
    'home.c7.t18': '2.47%Passing Rate',
    'home.c7.t19': 'Truly the best of the best',
    'home.c7.t20': 'Before Teaching',
    'home.c7.t21': 'Every teacher is trust- worthy',
    'home.c8.t1': 'Every teacher of Fangtian is good enough before going on the rostrum',
    'home.c8.t2': 'Lesson Preparation',
    'home.c8.t3': 'Teachers prepare for the exam every week to ensure that all the teachers’ class progress is uniform, the class content is consistent, and the quality of the class is guaranteed.',
    'home.c8.t4': 'Question Discussion',
    'home.c8.t5': 'The group leader leads everyone to solve problems, unify the lecture ideas, and ensure that each student can receive the same knowledge points and solution ideas.',
    'home.c8.t6': 'Lesson Competition',
    'home.c8.t7': 'We hold teaching competition irregularly, even if the teacher has taught many years, we must always test the level of the teacher and supervise the growth of the teacher.',
    'home.c8.t8': 'Lesson Examination',
    'home.c8.t9': 'Before the start of the class, the unified assessment will test the teacher’s class skills and preparation level, and only really excellent people can take on platform.',
    'home.c9.t1': 'About Fangtian',
    'home.c9.t2': 'Let Every Student Get High-quality Education.',
    'home.c9.t3': 'Since our establishment in July 2014, Fangtian Education is determined to use technology to change learning, and is committed to providing high-quality offline and online extracurricular tutoring for primary and secondary school students aged 6-18. As of the summer of 2018, Fangtian has trained more than one 40,000 trainee, and we have become a science and technology education institution trusted and recognized by primary and secondary school students and parents in Anhui Province.',
    'home.c9.t4': 'In order to achieve accurate teaching for each student, Fangtian has continuously helped children improve their learning efficiency through technological innovation since our establishment. In addition, Fangtian also implements an educational service system of offline small class teaching and online tutoring, and adopts the “7-steppedagogy” and the teaching assistant model to shift the focus of teaching from “Teacher teaches well” to “Student learns well”Let every student can get professional tutoring according to their aptitude.',
    'home.c10.t1': 'Common Questions',
    'home.c10.t2': 'Q：When can I register? How do I register?',
    'home.c10.t3': 'After the end of the test, you can use the test results to register and pay through the WeChat Mini Program.',
    'home.c10.t4': 'Q：What subjects does Fangtian offer? Will there be other subjects?',
    'home.c10.t5': 'Fangtian currently only offers Science subjects, Chinese and English. Fangtian’s principle of doing things is to focus, and we are very cautious about the offering of new subjects. In order to ensure good teaching quality and teaching services, we must be well prepared to open new subjects.',
    'home.c10.t6': 'Q： Can my class type still be promoted after registration?',
    'home.c10.t7': 'Yes, Fangtian’s hierarchical teaching is to put children who have the relatively close learning levels in the same class. In this way, on the one hand, it is conducive to the teacher’s control of the progress in class, and more importantly, it is for the child to let them learn in the appropriate class, which can help them follow the progress of the class',
    'home.c10.t8': 'Q：Can parents observe?',
    'home.c10.t9': 'Yes, parents can learn in class with students if they obey the rules of the class.',
    'home.c10.t10': 'Q： What should I do if I am not satisfied after registration?',
    'home.c10.t11': 'Fangtian supports free transfer and adjustment of classes. Parents can transfer or adjust classes on the Mini Program or the official website, and transfer to their favorite classes. You can also go to the front to ask the front teachers to help with the operation',
    'home.c10.t12': 'Q： Can I get a refund after registration?',
    'home.c10.t13': 'If the remaining lessons of each semester are not less than three times, the fee can be refunded at any time, and no single lesson can be refunded',
}
