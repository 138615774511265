
    import { Component, Vue, Watch, Prop, Emit } from 'vue-property-decorator'
    import { Caxios } from './../../utils/axios'
    @Component({
        name: 'SmsCode'
    })
    export default class SmsCode extends Vue {
        @Prop() phone!: string
        @Prop() countryCode!: string
        @Prop() placeholder!: string
        @Prop() branchSchoolId!: string
        @Prop() type!: string

        private smscode = ''
        private status = 0
        private smscodeStr: any = this.$t('common.t30')
        private restNum = 60
        private timer: any = null
        private curPhone = ''
        private branchId = ''

        @Watch('phone', { immediate: true, deep: true })
        phoneWatch(newVal: string): void {
            this.curPhone = newVal
        }
        @Watch('branchSchoolId', { immediate: true, deep: true })
        branchWatch(newVal: string): void {
            this.branchId = newVal
        }

        private focusEvent(): void {
            this.status = 0
            this.msgcodeCb(0)
        }
        @Emit()
        private msgcodeCb(status: number): any {
            return { smscode: this.smscode, status: status }
        }
        private blurEvent (): void {
            if(this.smscode) {
                this.status = 1
            } else {
                this.status = 2
            }
            this.msgcodeCb(1)
        }
        private getSmscodeEvent (): void {
            if (this.restNum != 60  || !this.curPhone || (!this.branchId && !this.type)) return
            if(!this.timer && this.curPhone) {
                this.timer = setInterval(() => {
                    if(this.restNum > 1) {
                        this.restNum -= 1
                        this.smscodeStr = `${this.$t('common.t11')}(${this.restNum})`
                    } else {
                        this.restNum = 60
                        this.smscodeStr = this.$t('common.t31')
                        clearInterval(this.timer)
                        this.timer = null
                    }
                }, 1000)
                this.smsAjax()
            }
        }
        private async smsAjax () {
            const _result: any = await Caxios.post({
                url:'/api/official/user/getVerificationCode',
                data:{
                    phone:this.curPhone,
                    branchSchoolCode:this.branchId,
                    countryCode: this.countryCode
                }
            })
            console.log(_result)
        }
    }
