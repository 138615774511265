import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import common from './module/common'
import course from './module/course'
import aboutUs from './module/aboutUs'
import teacher from './module/teacher'
import personal from './module/personal'
import personalKscf from './module/personalKscf'
import personalCollection from './module/personalCollection'
import personalTtl from './module/personalTtl'
import personalKc from './module/personalKc'

let modules = {
    common,
    course,
    aboutUs,
    teacher,
    personal,
    personalKscf,
    personalCollection,
    personalTtl,
    personalKc
}
export default new Vuex.Store({
    modules
})