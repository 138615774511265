import antd from 'ant-design-vue/es/locale-provider/zh_CN'
import common from './zh-CN/common'
import home from './zh-CN/home'
import course from './zh-CN/course'
import center from './zh-CN/center'

const components = {
  antLocale: antd
}

export default {
  ...components,
  ...common,
  ...home,
  ...course,
  ...center
}
