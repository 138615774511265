class Base64 {
    private _keyStr: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
    private _utf8_encode (str: string): string {
        str = str.replace(/\r\n/g,"\n")
        var utftext: string = ""
        for (var n = 0; n < str.length; n++) {
            var c = str.charCodeAt(n)
            if (c < 128) {
                utftext += String.fromCharCode(c)
            } else if((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192)
                utftext += String.fromCharCode((c & 63) | 128)
            } else {
                utftext += String.fromCharCode((c >> 12) | 224)
                utftext += String.fromCharCode(((c >> 6) & 63) | 128)
                utftext += String.fromCharCode((c & 63) | 128)
            }

        }
        return utftext
    }
    private _utf8_decode (utftext: string): string {
        var str: string = "",
            i: number = 0,
            c: number = 0,
            c1: number = 0,
            c2: number = 0;
        while ( i < utftext.length ) {
            c = utftext.charCodeAt(i)
            if (c < 128) {
                str += String.fromCharCode(c)
                i++;
            } else if((c > 191) && (c < 224)) {
                c1 = utftext.charCodeAt(i+1)
                str += String.fromCharCode(((c & 31) << 6) | (c1 & 63))
                i += 2
            } else {
                c1 = utftext.charCodeAt(i+1)
                c2 = utftext.charCodeAt(i+2)
                str += String.fromCharCode(((c & 15) << 12) | ((c1 & 63) << 6) | (c2 & 63))
                i += 3
            }
        }
        return str
    }
    public encode (input: string): string {
        let _output: string = '',
            chr1: number = 0,
            chr2: number = 0,
            chr3: number = 0,
            enc1: number = 0,
            enc2: number = 0,
            enc3: number = 0,
            enc4: number = 0,
            i: number = 0;
        input = this._utf8_encode(input)
        while (i < input.length) {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            _output = _output +
                this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
                this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);
        }
        return _output
    }
    public decode (input: string): string {
        let _output: string = '',
            chr1: number = 0,
            chr2: number = 0,
            chr3: number = 0,
            enc1: number = 0,
            enc2: number = 0,
            enc3: number = 0,
            enc4: number = 0,
            i: number = 0;
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        while (i < input.length) {
            enc1 = this._keyStr.indexOf(input.charAt(i++));
            enc2 = this._keyStr.indexOf(input.charAt(i++));
            enc3 = this._keyStr.indexOf(input.charAt(i++));
            enc4 = this._keyStr.indexOf(input.charAt(i++));
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;
            _output = _output + String.fromCharCode(chr1);
            if (enc3 != 64) {
                _output = _output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                _output = _output + String.fromCharCode(chr3);
            }
        }
        _output = this._utf8_decode(_output);
        return _output;
    }
}
export function secretKey (str: any, pwd: string, type: string): any {
    let b: Base64 = new Base64()

    if(type=='encryption'){
        str = b.encode(str);//Base64加密
        let prand: any = "";
        for(let i = 0; i < pwd.length; i++) {
            prand += pwd.charCodeAt(i).toString();
        }
        let sPos: number = Math.floor(prand.length / 5),
            mult: number = parseInt(prand.charAt(sPos) + prand.charAt(sPos*2) + prand.charAt(sPos*3) + prand.charAt(sPos*4) + prand.charAt(sPos*5)),
            incr: number = Math.ceil(pwd.length / 2),
            modu: number = Math.pow(2, 31) - 1;
        if(mult < 2) {
            alert("Please choose a more complex or longer password.");
            return '';
        }
        let salt: any = Math.round(Math.random() * 1000000000) % 100000000;
        prand += salt;
        while(prand.length > 10) {
            prand = (parseInt(prand.substring(0, 10)) + parseInt(prand.substring(10, prand.length))).toString();
        }
        prand = (mult * prand + incr) % modu;
        let enc_chr: any = '',
            enc_str: any = '';
        for(let i = 0; i < str.length; i++) {
            let temp: any = str.charCodeAt(i) ^ Math.floor((prand / modu) * 255)
            enc_chr = parseInt(temp);
            if(enc_chr < 16) {
                enc_str += "0" + enc_chr.toString(16);
            } else enc_str += enc_chr.toString(16);
            prand = (mult * prand + incr) % modu;
        }
        salt = salt.toString(16);
        while(salt.length < 8)salt = "0" + salt;
        enc_str += salt;
        return enc_str;
    }
    if(type=='decryption'){
        let prand: any = "";
        for(let i = 0; i < pwd.length; i++) {
            prand += pwd.charCodeAt(i).toString();
        }
        let sPos: number = Math.floor(prand.length / 5),
            mult: number = parseInt(prand.charAt(sPos) + prand.charAt(sPos*2) + prand.charAt(sPos*3) + prand.charAt(sPos*4) + prand.charAt(sPos*5)),
            incr: number = Math.round(pwd.length / 2),
            modu: number = Math.pow(2, 31) - 1,
            salt: number = parseInt(str.substring(str.length - 8, str.length), 16);
        str = str.substring(0, str.length - 8);
        prand += salt;
        while(prand.length > 10) {
            prand = (parseInt(prand.substring(0, 10)) + parseInt(prand.substring(10, prand.length))).toString();
        }
        prand = (mult * prand + incr) % modu;
        let enc_chr: any = "",
            enc_str: any = "";
        for(var i=0; i<str.length; i+=2) {
            let temp: any = parseInt(str.substring(i, i + 2), 16) ^ Math.floor((prand / modu) * 255)
            enc_chr = parseInt(temp);
            enc_str += String.fromCharCode(enc_chr);
            prand = (mult * prand + incr) % modu;
        }
        //return enc_str;
        return b.decode(enc_str);
    }
}