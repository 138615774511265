
    import { Component, Vue } from 'vue-property-decorator'
    import { checkPhone, checkSPhone, checkHPhone } from './../../utils/validate'

    import { Caxios } from './../../utils/axios'
    import { mapMutations } from 'vuex'

    import UseName from './../username/index.vue'
    import UsePwd from './../password/index.vue'
    import SmsCode from './../msgCode/index.vue'

    @Component({
        name: 'MineForget',
        components: { UseName, UsePwd, SmsCode },
        methods: {
            ...mapMutations('common', ['setCommonComponentName']),
        }
    })
    export default class MineForget extends Vue {
        setCommonComponentName!: any
        private usernameMsg: any = ''
        private passwordMsg: any = ''
        private passwordMsg2: any = ''
        private smscodeMsg: any = ''
        private phoneType: any = ''
        private differentFlag1 = false
        private differentFlag2 = false
        private loginForm: any = {
            phone: '',
            newPassword: '',
            confirmPassword: '',
            verificationCode: ''
        }
        private usenameCb (obj: any): void {
            const { phone, status, phoneType } = obj
            // this.loginForm.phone = phone
            let phoneSuccess = false
            if (phoneType === '65') {
                phoneSuccess = checkSPhone(phone)
            } else if (phoneType === '852') {
                phoneSuccess = checkHPhone(phone)
            } else if (phoneType === '86') {
                phoneSuccess = checkPhone(phone)
            }
            this.phoneType = phoneType
            if(phoneSuccess) {
                this.usernameMsg = ''
                this.loginForm.phone = phone
            } else {
                this.usernameMsg = this.$t('common.t6')
                this.loginForm.phone = ''
            }
            if(status == 0) {
                this.usernameMsg = ''
            }
        }
        private usepwdCb (obj: any): void {
            const { password, status } = obj
            this.loginForm.newPassword = password
            if(password) {
                if(password.length < 8 || password.length > 12) {
                    this.passwordMsg = this.$t('common.t20')
                } else if(password && this.loginForm.confirmPassword && this.loginForm.confirmPassword != password) {
                    this.differentFlag1 = true
                    this.passwordMsg = this.$t('common.t32')
                } else {
                    this.resetPwd()
                }
            } else {
                this.passwordMsg = this.$t('common.t21')
            }



            if(status == 0) {
                this.passwordMsg = ''
            }
        }
        private usepwdCb2 (obj: any): void {
            const { password, status } = obj
            this.loginForm.confirmPassword = password

            if(password) {
                if(password.length < 8 || password.length > 12) {
                    this.passwordMsg2 = this.$t('common.t20')
                } else if(password && this.loginForm.newPassword && this.loginForm.newPassword != password) {
                    this.differentFlag2 = true
                    this.passwordMsg2 = this.$t('common.t32')
                } else {
                    this.resetPwd()
                }
            } else {
                this.passwordMsg2 = this.$t('common.t21')
            }

            if(status == 0) {
                this.passwordMsg2 = ''
            }
        }
        private resetPwd (): void {
            this.differentFlag1 = false
            this.passwordMsg = ''
            this.differentFlag2 = false
            this.passwordMsg2 = ''
        }
        private msgcodeCb (obj: any): void {
            const { smscode, status } = obj
            this.loginForm.verificationCode = smscode

            if(smscode) {
                this.smscodeMsg = ''
            } else {
                this.smscodeMsg = this.$t('common.t22')
            }
            if(status == 0) {
                this.smscodeMsg = ''
            }
        }
        private async curLoginEvent () {
            const _data: any = { ...this.loginForm },
                _this: any = this;
            if(!this.usernameMsg && !this.passwordMsg && !this.passwordMsg2 && !this.smscodeMsg) {
                const _result: any = await Caxios.post({ url: '/api/official/user/updatePassword', data: _data })
                console.log('_result',_result)
                if(_result.code === '10000') {
                    _this.$message.success(this.$t('common.t33'))
                    this.setCommonComponentName('MineLogin')
                } else{
                    _this.$message.warning(_result.msg)
                }
            }
        }
    }
